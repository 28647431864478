// Auth actions
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH";
export const AUTH_RESET = "AUTH_RESET";

// currentUser actions
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const REFRESH_CURRENT_USER = "REFRESH_CURRENT_USER";

// Upload actions
export const UPLOAD_START = "UPLOAD_START";
export const UPLOAD_SUCCESS = "UPLOAD_SUCCESS";
export const UPLOAD_FAIL = "UPLOAD_FAIL";
export const UPLOAD_RESET = "UPLOAD_RESET";

// Profile actions
export const EDIT_PROFILE_START = "EDIT_PROFILE_START";
export const EDIT_PROFILE_SUCCESS = "EDIT_PROFILE_SUCCESS";
export const EDIT_PROFILE_FAIL = "EDIT_PROFILE_FAIL";
export const UPDATE_EMAIL_START = "UPDATE_EMAIL_START";
export const UPDATE_EMAIL_SUCCESS = "UPDATE_EMAIL_SUCCESS";
export const UPDATE_EMAIL_FAIL = "UPDATE_EMAIL_FAIL";
export const UPDATE_EMAIL_RESET = "UPDATE_EMAIL_RESET";
export const UPDATE_EMAIL_VERIFY_START = "UPDATE_EMAIL_VERIFY_START";
export const UPDATE_EMAIL_VERIFY_SUCCESS = "UPDATE_EMAIL_VERIFY_SUCCESS";
export const UPDATE_EMAIL_VERIFY_FAIL = "UPDATE_EMAIL_VERIFY_FAIL";
export const UPDATE_EMAIL_VERIFY_RESET = "UPDATE_EMAIL_VERIFY_RESET";
export const UPDATE_EMAIL_RESEND_START = "UPDATE_EMAIL_RESEND_START";
export const UPDATE_EMAIL_RESEND_SUCCESS = "UPDATE_EMAIL_RESEND_SUCCESS";
export const UPDATE_EMAIL_RESEND_FAIL = "UPDATE_EMAIL_RESEND_FAIL";
export const UPDATE_EMAIL_RESEND_RESET = "UPDATE_EMAIL_RESEND_RESET";

// CreateUser actions
export const CREATE_USER_START = "CREATE_USER_START";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAIL = "CREATE_USER_FAIL";
export const CREATE_USER_RESET = "CREATE_USER_RESET";
export const CREATE_USER_VERIFY_START = "CREATE_USER_VERIFY_START";
export const CREATE_USER_VERIFY_SUCCESS = "CREATE_USER_VERIFY_SUCCESS";
export const CREATE_USER_VERIFY_FAIL = "CREATE_USER_VERIFY_FAIL";
export const CREATE_USER_VERIFY_RESET = "CREATE_USER_VERIFY_RESET";
export const CREATE_USER_RESEND_START = "CREATE_USER_RESEND_START";
export const CREATE_USER_RESEND_SUCCESS = "CREATE_USER_RESEND_SUCCESS";
export const CREATE_USER_RESEND_FAIL = "CREATE_USER_RESEND_FAIL";
export const CREATE_USER_RESEND_RESET = "CREATE_USER_RESEND_RESET";

// ForgotPassword actions
export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";
export const FORGOT_PASSWORD_RESET = "FORGOT_PASSWORD_RESET";
export const FORGOT_PASSWORD_VERIFY_START = "FORGOT_PASSWORD_VERIFY_START";
export const FORGOT_PASSWORD_VERIFY_SUCCESS = "FORGOT_PASSWORD_VERIFY_SUCCESS";
export const FORGOT_PASSWORD_VERIFY_FAIL = "FORGOT_PASSWORD_VERIFY_FAIL";
export const FORGOT_PASSWORD_VERIFY_RESET = "FORGOT_PASSWORD_VERIFY_RESET";
export const FORGOT_PASSWORD_RESEND_START = "FORGOT_PASSWORD_RESEND_START";
export const FORGOT_PASSWORD_RESEND_SUCCESS = "FORGOT_PASSWORD_RESEND_SUCCESS";
export const FORGOT_PASSWORD_RESEND_FAIL = "FORGOT_PASSWORD_RESEND_FAIL";
export const FORGOT_PASSWORD_RESEND_RESET = "FORGOT_PASSWORD_RESEND_RESET";

// User actions
export const USER_FETCH_START = "USER_FETCH_START";
export const USER_FETCH_FAIL = "USER_FETCH_FAIL";
export const USER_FETCH_SUCCESS = "USER_FETCH_SUCCESS";
export const USER_FETCH_RESET = "USER_FETCH_RESET";
export const REFRESH_USER = "REFRESH_USER";

// PaginatePost actions
export const POSTS_FETCH_START = "POSTS_FETCH_START";
export const POSTS_FETCH_FAIL = "POSTS_FETCH_FAIL";
export const POSTS_FETCH_SUCCESS = "POSTS_FETCH_SUCCESS";
export const POSTS_FETCH_RESET = "POSTS_FETCH_RESET";
export const REFRESH_POSTS = "REFRESH_POSTS";

// PaginateUser actions
export const USERS_FETCH_START = "USERS_FETCH_START";
export const USERS_FETCH_FAIL = "USERS_FETCH_FAIL";
export const USERS_FETCH_SUCCESS = "USERS_FETCH_SUCCESS";
export const USERS_FETCH_RESET = "USERS_FETCH_RESET";
export const REFRESH_USERS = "REFRESH_USERS";
